import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Card, Row, Col, Container, Form } from 'react-bootstrap';
import moment from 'moment';

import API_BASE_URL from "../apiConfig";

const LiveProjectData = ({ projectId }) => {
  const [liveData, setLiveData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [projectData, setProjectData] = useState();
  const [liveTime, setLiveTime] = useState();
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState("Today"); 

  const fetchLiveData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/pixelStripeApi/streamlog/projectId/${projectId}`);
      if (response.status === 200) {
        const groupedData = groupDataBySessionId(response.data);
        setLiveData(groupedData.filter(e => e.latency !== null));
      }
    } catch (error) {
      console.error('Error fetching live data', error);
    } finally {
      setLoading(false);
    }
  };
  const fetchLiveStats = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/pixelStripeApi/projects/${projectId}`);
      if (response.status === 200) {
        setProjectData(response.data);
      }
    } catch (error) {
      console.error('Error fetching project data', error);
    }
  };

  const fetchLiveUpdateTime = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/pixelStripeApi/streamlog/lastRunTime`);
      if (response.status === 200) {
        console.log(response.data);
        setLiveTime(response.data);
      }
    } catch (error) {
      console.error('Error fetching project data', error);
    }
  };

  useEffect(() => {
    fetchLiveStats();
    fetchLiveUpdateTime(); 
  
    const intervalId = setInterval(() => {
      fetchLiveStats();
      fetchLiveUpdateTime(); 
    }, 30000); 
  
    return () => clearInterval(intervalId);
  }, [projectId]);
  

  useEffect(() => {
    fetchLiveData();
  }, [projectId]);

  useEffect(() => {
    applyFilter();
  }, [filter, liveData]);

  const groupDataBySessionId = (data) => {
    const grouped = {};
    data.forEach((event) => {
      const { sessionId, event: eventType } = event;

      if (!grouped[sessionId]) {
        grouped[sessionId] = {
          sessionId,
          startTime: null,
          endTime: null,
          projectId: null,
          os: null,
          city: null,
          region: null,
          projectRegion: null,
          browser: null,
          hxw: null,
          latency: null,
          timeToLoad: 0,
          fps: null,
          duration: 0,
        };
      }

      if (eventType === "Stream Start") {
        grouped[sessionId].startTime = event.entryTime;
        grouped[sessionId].projectId = event.projectId;
        grouped[sessionId].os = event.os;
        grouped[sessionId].city = event.city;
        grouped[sessionId].region = event.region;
        grouped[sessionId].projectRegion = event.projectRegion;
        grouped[sessionId].country = event.country;
      }

      if (eventType === "Stream End") {
        grouped[sessionId].endTime = event.entryTime;
        grouped[sessionId].duration = event.duration;
      }

      if (eventType === "Stream Loaded") {
        grouped[sessionId].loadTime = event.entryTime;
        grouped[sessionId].timeToLoad = event.timeToLoad;
        grouped[sessionId].browser = event.browser;
        grouped[sessionId].hxw = event.hxw;
        grouped[sessionId].latency = event.latency;
        grouped[sessionId].fps = event.fps;
      }
    });

    return Object.values(grouped);
  };

  const applyFilter = () => {
    const now = moment();
    let filtered = [...liveData];

    switch (filter) {
      case "Today":
        filtered = filtered.filter(session => moment(session.startTime).isSame(now, 'day'));
        break;
      case "Yesterday":
        filtered = filtered.filter(session => moment(session.startTime).isSame(moment().subtract(1, 'days'), 'day'));
        break;
      case "Last 7 Days":
        filtered = filtered.filter(session => moment(session.startTime).isAfter(moment().subtract(7, 'days')));
        break;
      case "Last 14 Days":
        filtered = filtered.filter(session => moment(session.startTime).isAfter(moment().subtract(14, 'days')));
        break;
      case "This Month":
        filtered = filtered.filter(session => moment(session.startTime).isSame(now, 'month'));
        break;
      case "Last Month":
        filtered = filtered.filter(session => moment(session.startTime).isSame(moment().subtract(1, 'months'), 'month'));
        break;
      default:
        break;
    }

    setFilteredData(filtered);
  };

  const totalDurationInMinutes = filteredData.reduce((total, session) => total + (session.duration), 0);

  return (
    <div style={{ marginTop: "20px" }}>
      <Row>
        <Col md={3} sm={6}>
          <Card style={{ minHeight: '150px', width: '100%', maxWidth: '250px', borderRadius: '12px', background: "#161616", borderColor: "#fff", cursor: 'pointer', textAlign: "center" }}>
            <Card.Header>Live Users (Real Time)</Card.Header>
            <Card.Body>
              <Card.Title style={{ fontSize: "42px" }}>{projectData && projectData.liveUsers}</Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col md={3} sm={6} style={{ marginLeft: "-2%" }}>
          <Card style={{ minHeight: '150px', width: '100%', maxWidth: '250px', borderRadius: '12px', background: "#161616", borderColor: "#fff", cursor: 'pointer', textAlign: "center" }}>
            <Card.Header>Users in Queue (Real Time)</Card.Header>
            <Card.Body>
              <Card.Title style={{ fontSize: "42px" }}>{projectData && projectData.usersInQueue}</Card.Title>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <br />
      

      <h5>Streaming Logs:</h5>
      <p>Logs are updated once every 30 minutes.</p>
      <p>[Last Updated at : {moment(liveTime).local().format('DD/MM/YYYY HH:mm')}]</p>
    

      <Row>

        <Col md={{ span: 2, offset:10}}>
          <Form.Group>
            <Form.Label>Filter</Form.Label>
            <Form.Control as="select" value={filter} onChange={(e) => setFilter(e.target.value)}>
              <option>Today</option>
              <option>All Time</option>
              <option>Yesterday</option>
              <option>Last 7 Days</option>
              <option>Last 14 Days</option>
              <option>This Month</option>
              <option>Last Month</option>
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>
      <br />
     

      <Row>
        <Col>
          <Table striped bordered hover variant="dark">
            <thead>
              <tr>
                <th>Start Date</th>
                <th>Start Time</th>
                <th>Streaming ID</th>
                <th>OS</th>
                <th>Region</th>
                <th>Country</th>
                <th>Browser</th>
                <th>Screen WxH</th>
                <th>FPS</th>
                <th>Latency (ms)</th>
                <th>Loading Time (seconds)</th>
                <th>Duration (min)</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr><td colSpan="12" style={{ textAlign: "center" }}>Loading...</td></tr>
              ) : filteredData.length === 0 ? (
                <tr><td colSpan="12" style={{ textAlign: "center" }}>No Data Available</td></tr>
              ) : (
                filteredData.sort((a, b) => new Date(b.startTime) - new Date(a.startTime)).map(session => (
                  <tr key={session.sessionId}>
                    <td>{moment(session.startTime).local().format('DD/MM/YYYY')}</td>
                    <td>{moment(session.startTime).local().format('HH:mm')}</td>
                    <td>{session.sessionId}</td>
                    <td>{session.os}</td>
                    <td>{session.region}</td>
                    <td>{session.country}</td>
                    <td>{session.browser}</td>
                    <td>{session.hxw}</td>
                    <td>{session.fps}</td>
                    <td>{session.latency}</td>
                    <td>{session.timeToLoad}</td>
                    <td>{session.duration}</td>
                  </tr>
                ))
              )}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="11" style={{ textAlign: "right", paddingRight: "10px" }}>
                  Total Duration (min)
                </td>
                <td>{totalDurationInMinutes}</td>
              </tr>
            </tfoot>
          </Table>
        </Col>
      </Row>
    </div>
  );
};

export default LiveProjectData;
