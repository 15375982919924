import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Modal, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import API_BASE_URL from "../apiConfig";


function CreateInvoice({ user }) {
  const [invoices, setInvoices] = useState([]);
  const [editableInvoice, setEditableInvoice] = useState(null);

  // State for the modal form
  const [showModal, setShowModal] = useState(false);
  const [newInvoiceData, setNewInvoiceData] = useState({
    projectId: '',
    userId: '',
    currency: '',
    amount: 0,
    startAt: '',
    endAt: '',
    paymentId: '',
    subId: '',
  });

  useEffect(() => {
    axios.get(`${API_BASE_URL}/pixelStripeApi/invoices`)
      .then(response => {
        setInvoices(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the invoices!', error);
      });
  }, []);

  const handleInputChange = (e, field, invoiceId) => {
    const value = e.target.value;
    const updatedInvoices = invoices.map(invoice => {
      if (invoice._id === invoiceId) {
        invoice[field] = value;
      }
      return invoice;
    });
    setInvoices(updatedInvoices);

    const editedInvoice = invoices.find(invoice => invoice._id === invoiceId);
    editedInvoice[field] = value;
    setEditableInvoice(editedInvoice);
  };

  const handleSave = (invoiceId) => {


    const newInvoice = {
      adminId: user._id,
      webIp: process.env.REACT_APP_WEBIP,
    };

    axios.put(`${API_BASE_URL}/pixelStripeApi/createCustomeInvoice/${invoiceId}`, newInvoice)
      .then(response => {
        alert('Invoice updated successfully!');
        setEditableInvoice(null);
      })
      .catch(error => {
        console.error('There was an error updating the invoice!', error);
      });
  };

  const handleCreate = () => {
    // Open the modal when creating a new invoice
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleModalSubmit = (e) => {
    e.preventDefault();

    const newInvoice = {
      ...newInvoiceData,
      adminId: user._id,
      webIp: process.env.REACT_APP_WEBIP,
    };

    axios.post(`${API_BASE_URL}/pixelStripeApi/createCustomeInvoice/create`, newInvoice)
      .then(response => {
        alert('Invoice created successfully!');
        setInvoices([...invoices, response.data.invoice]);
        setShowModal(false); 
        setNewInvoiceData({
          projectId: '',
          userId: '',
          currency: '',
          amount: 0,
          startAt: '',
          endAt: '',
          paymentId: '',
          subId: '',
        }); 
            })
      .catch(error => {
        console.error('There was an error creating the invoice!', error);
      });
  };

  const handleChangeModal = (e) => {
    const { name, value } = e.target;
    setNewInvoiceData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div className="container mt-5">
      <h2>Editable Invoice Table</h2>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Invoice Number</th>
            <th>Currency</th>
            <th>Amount</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {invoices.map((invoice,index) => (
            <tr key={invoice._id}>
              <td>
                <input
                disabled
                  type="text"
                  value={index+1}
                />
              </td>
              <td>
                <input
                disabled
                  type="text"
                  value={invoice.currency}
                />
              </td>
              <td>
                <input
                disabled
                  type="number"
                  value={invoice.amount}
                />
              </td>
              <td>
                <input
                 value={invoice.startAt}
                 disabled
                 
                />
              </td>
              <td>
              <input
                 value={invoice.endAt}
                 disabled
                 
                />
              </td>

              <td>
              <a href={invoice.invoiceUrl} target='_blank'>
                  View Invoice
                </a>
              </td>
              <td>
                <Button variant="primary" onClick={() => handleSave(invoice._id)}>
                  Update
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Button to trigger modal for creating a new invoice */}
      <Button variant="success" onClick={handleCreate}>Create New Invoice</Button>

      {/* Modal for creating a new invoice */}
      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Invoice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleModalSubmit}>
            <Form.Group controlId="formProjectId">
              <Form.Label>Project ID</Form.Label>
              <Form.Control
                type="text"
                name="projectId"
                value={newInvoiceData.projectId}
                onChange={handleChangeModal}
                required
              />
            </Form.Group>


            <Form.Group controlId="userId">
              <Form.Label>User ID</Form.Label>
              <Form.Control
                type="text"
                name="userId"
                value={newInvoiceData.userId}
                onChange={handleChangeModal}
                required
              />
            </Form.Group>

            <Form.Group controlId="formCurrency">
              <Form.Label>Currency</Form.Label>
              <Form.Control
                type="text"
                name="currency"
                value={newInvoiceData.currency}
                onChange={handleChangeModal}
                required
              />
            </Form.Group>
            <Form.Group controlId="formAmount">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="number"
                name="amount"
                value={newInvoiceData.amount}
                onChange={handleChangeModal}
                required
              />
            </Form.Group>
            <Form.Group controlId="formStartDate">
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                type="date"
                name="startAt"
                value={newInvoiceData.startAt}
                onChange={handleChangeModal}
                required
              />
            </Form.Group>
            <Form.Group controlId="formEndDate">
              <Form.Label>End Date</Form.Label>
              <Form.Control
                type="date"
                name="endAt"
                value={newInvoiceData.endAt}
                onChange={handleChangeModal}
                required
              />
            </Form.Group>
            <Form.Group controlId="formPaymentId">
              <Form.Label>Payment ID</Form.Label>
              <Form.Control
                type="text"
                name="paymentId"
                value={newInvoiceData.paymentId}
                onChange={handleChangeModal}
                required
              />
            </Form.Group>
            <Form.Group controlId="formSubId">
              <Form.Label>Subscription ID</Form.Label>
              <Form.Control
                type="text"
                name="subId"
                value={newInvoiceData.subId}
                onChange={handleChangeModal}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Create Invoice
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default CreateInvoice;
