import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { FaArrowLeft } from 'react-icons/fa';
import { Button, Modal } from 'react-bootstrap';
import mixpanel from '../mixpanel';
import API_BASE_URL from "../apiConfig";

const CreateProjectTrial = ({ user,userCountry }) => {
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [createProjectStart, setCreateProjectStart] = useState(false);
    const [shouldSubmit, setShouldSubmit] = useState(false);
    const [region, setRegion] = useState(''); 

    const navigate = useNavigate();
    const rzpRef = useRef();


    const handleChange = (e) => {
        setName(e.target.value);
    };

    const handleRegionChange = (e) => {
        setRegion(e.target.value); 
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (user.trialDone === false) {
            setShowModal(true);
        } else {
            alert('Already User Free Trial');
            navigate('/dashboard');
        }
    };

    const handleContinue = async () => {
        setShowModal(false);
        setCreateProjectStart(true);
    };

    useEffect(() => {

       

        
        if (createProjectStart) {
            const createProject = async () => {
                 
                try {
                    setLoading(true); 
                    const endDate = moment().clone().add(2, 'days');
                    const unixEndDate = endDate.unix();

                   

                    const response = await axios.post(`${API_BASE_URL}/pixelStripeApi/projects`, {
                        name,
                        status: false,
                        user: user._id,
                        isLocal: false,
                        allowedUsers: 2,
                        isFreeTrial: true,
                        subscriptionStartDate: unixEndDate,
                        paymentMethodAttached: false,
                        subStatus: "freeTrial",
                        webIp: process.env.REACT_APP_WEBIP,
                        region:region 

                    });

                    const projectId = response.data.projectId;
                    await axios.put(`${API_BASE_URL}/pixelStripeApi/users/${user._id}`, {
                        userId: user._id,
                        isTrialDone: true,
                        webIp: process.env.REACT_APP_WEBIP
                    });

                    mixpanel.track('Project Created', {
                        "UserId": user._id,
                        "Project Id":response.data.projectId,
                        "Project Name":name,
                        "Region": region 
                      });
                

                    navigate(`/projectDetail/${projectId}`);
                } catch (error) {
                    console.error('Error creating project:', error);
                } finally {
                    setLoading(false); // Stop loading indicator
                }
            };

            createProject();
            setCreateProjectStart(false);
        }
    }, [createProjectStart, name, navigate, user]);

    const handleBackClick = () => {
        navigate('/dashboard');
    };

    return (
        <div>
            {loading && (
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    <p style={{ marginTop: '10px' }}>Please wait...</p>
                </div>
            )}

            {!loading && (
                <>
                    <br />
                    <Button size='sm' variant="primary" onClick={handleBackClick}>
                        <FaArrowLeft /> Back
                    </Button>
                    <br />
                    <br />
                    <h5>Create a Project:</h5>
                  <form onSubmit={handleSubmit}>
             
                                     <div className="mb-3">
                                         <label htmlFor="name" className="form-label">Project Name</label>
                                         <input type="text" className="form-control" id="name" value={name} onChange={handleChange} required />
                                     </div>
          
                                     <div className="mb-3">
                                         <label htmlFor="region" className="form-label">Select Your Server Region</label>
                                         <p>Choose the server closest to your users for the best experience</p>

                                         <select id="region" className="form-control" value={region} onChange={handleRegionChange} required>
                                             <option value="">Select Region</option>
                                             <option value="Europe">Europe</option>
                                             <option value="Asia Pacific">Asia Pacific</option>
                                         </select>
                                     </div>
             
                                     <Button type="submit" className="btn btn-primary">Continue</Button>
                                 </form>
                </>
            )}

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Free Trial Terms</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <h6>
                            You are about to activate your free trial. The trial allows you 60 minutes of streaming or lasts for two days from activation, whichever comes first.
                            <br />
                            Do you wish to continue?
                        </h6>
                        <br />
                        <Button onClick={handleContinue}>Continue</Button>
                    </div>
                    <br />
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default CreateProjectTrial;
