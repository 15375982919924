import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Table from 'react-bootstrap/Table';
import Pagination from 'react-bootstrap/Pagination';
import Form from 'react-bootstrap/Form';
import API_BASE_URL from "../apiConfig";
import moment from 'moment';

const StreamingLogsTable = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [filter, setFilter] = useState("All Time");
  const [liveTime, setLiveTime] = useState();
  
  const [searchQuery, setSearchQuery] = useState(""); 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/pixelStripeApi/streamlog`);
        if (response.status === 200) {
          const groupedData = groupDataBySessionId(response.data);
          setData(groupedData.filter(e => e.latency !== null));
        }
      } catch (error) {
        console.error('Error fetching live data', error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    applyFilter();
  }, [filter, searchQuery, data]);

  const groupDataBySessionId = (data) => {
    const grouped = {};

    data.forEach((event) => {
      const { sessionId, event: eventType } = event;

      if (!grouped[sessionId]) {
        grouped[sessionId] = {
          sessionId,
          startTime: null,
          endTime: null,
          projectId: null,
          os: null,
          city: null,
          region: null,
          projectRegion: null,
          browser: null,
          hxw: null,
          latency: null,
          timeToLoad: 0,
          fps: null,
          duration: 0,
        };
      }

      if (eventType === "Stream Start") {
        grouped[sessionId].startTime = event.entryTime;
        grouped[sessionId].projectId = event.projectId;
        grouped[sessionId].os = event.os;
        grouped[sessionId].city = event.city;
        grouped[sessionId].region = event.region;
        grouped[sessionId].projectRegion = event.projectRegion;
        grouped[sessionId].country = event.country;
      }

      if (eventType === "Stream End") {
        grouped[sessionId].endTime = event.entryTime;
        grouped[sessionId].duration = event.duration;
      }

      if (eventType === "Stream Loaded") {
        grouped[sessionId].loadTime = event.entryTime;
        grouped[sessionId].timeToLoad = event.timeToLoad;
        grouped[sessionId].browser = event.browser;
        grouped[sessionId].hxw = event.hxw;
        grouped[sessionId].latency = event.latency;
        grouped[sessionId].fps = event.fps;
      }
    });

    return Object.values(grouped);
  };

  const applyFilter = () => {
    const now = moment();
    let filtered = [...data];

    switch (filter) {
      case "Today":
        filtered = filtered.filter(session => moment(session.startTime).isSame(now, 'day'));
        break;
      case "Yesterday":
        filtered = filtered.filter(session => moment(session.startTime).isSame(moment().subtract(1, 'days'), 'day'));
        break;
      case "Last 7 Days":
        filtered = filtered.filter(session => moment(session.startTime).isAfter(moment().subtract(7, 'days')));
        break;
      case "Last 14 Days":
        filtered = filtered.filter(session => moment(session.startTime).isAfter(moment().subtract(14, 'days')));
        break;
      case "This Month":
        filtered = filtered.filter(session => moment(session.startTime).isSame(now, 'month'));
        break;
      case "Last Month":
        filtered = filtered.filter(session => moment(session.startTime).isSame(moment().subtract(1, 'months'), 'month'));
        break;
      default:
        break;
    }


  if (searchQuery.trim() !== "") {
    filtered = filtered.filter(session => session.projectId && session.projectId.includes(searchQuery));
  }

  filtered = filtered.sort((a, b) => new Date(b.startTime) - new Date(a.startTime));

  setFilteredData(filtered);

  };


  const fetchLiveUpdateTime = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/pixelStripeApi/streamlog/lastRunTime`);
      if (response.status === 200) {
        console.log(response.data);
        setLiveTime(response.data);
      }
    } catch (error) {
      console.error('Error fetching project data', error);
    }
  };

  useEffect(() => {
    fetchLiveUpdateTime();
    const intervalId = setInterval(fetchLiveUpdateTime, 60000); 
    return () => clearInterval(intervalId); 
  }, []);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div>
      <h2>Streaming Logs</h2>
     <p>last updated at : {moment(liveTime).local().format('DD/MM/YYYY HH:mm')}</p>
      

      <div style={{ display: "flex", gap: "20px", marginBottom: "20px" }}>
        <Form.Group style={{ width: "200px" }}>
          <Form.Label>Filter by Date Range:</Form.Label>
          <Form.Control as="select" value={filter} onChange={(e) => setFilter(e.target.value)}>
            <option>All Time</option>
            <option>Today</option>
            <option>Yesterday</option>
            <option>Last 7 Days</option>
            <option>Last 14 Days</option>
            <option>This Month</option>
            <option>Last Month</option>
          </Form.Control>
        </Form.Group>

        <Form.Group style={{ width: "200px" }}>
          <Form.Label>Search by Project ID:</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Project ID"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </Form.Group>
      </div>

      <Table striped bordered hover variant="dark">
        <thead>
          <tr>
            <th>Start Date</th>
            <th>Start Time</th>
            <th>Streaming ID</th>
            <th>Project ID</th>
            <th>Project Region</th>
            <th>OS</th>
            <th>Region</th>
            <th>Country</th>
            <th>Browser</th>
            <th>Screen WxH</th>
            <th>FPS</th>
            <th>Latency (ms)</th>
            <th>Loading Time (seconds)</th>
            <th>Duration (min)</th>
          </tr>
        </thead>
        <tbody>
          
          {currentItems.map((session) => (
              <tr key={session.sessionId}>
                <td>{moment(session.startTime).local().format('DD/MM/YYYY')}</td>
                <td>{moment(session.startTime).local().format('HH:mm')}</td>
                <td>{session.sessionId}</td>
                <td>{session.projectId}</td>
                <td>{session.projectRegion}</td>
                <td>{session.os}</td>
                <td>{session.region}</td>
                <td>{session.country}</td>
                <td>{session.browser}</td>
                <td>{session.hxw}</td>
                <td>{session.fps}</td>
                <td>{session.latency}</td>
                <td>{session.timeToLoad}</td>
                <td>{session.duration}</td>
              </tr>
            ))}
        </tbody>
      </Table>

      <Pagination>
        <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
        <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />

        {Array.from({ length: totalPages }, (_, i) => i + 1).map((pageNumber) => (
          <Pagination.Item
            key={pageNumber}
            active={pageNumber === currentPage}
            onClick={() => handlePageChange(pageNumber)}
          >
            {pageNumber}
          </Pagination.Item>
        ))}

        <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
        <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
      </Pagination>
    </div>
  );
};

export default StreamingLogsTable;
