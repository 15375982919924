import React, { useEffect, useState } from 'react';
import { Container, Button, Toast, Row, Col, Form } from "react-bootstrap";
import axios from 'axios';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import API_BASE_URL from "../apiConfig";

const LiveStats = () => {

    const [liveData, setLiveData] = useState({}); 

    const fetchLiveData = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/pixelStripeApi/projects`);
            if (response.status === 200) {
        
            const filteredData =  response.data.filter(e=> e.liveUsers > 0 || e.usersInQueue>0);   
   
                setLiveData(filteredData); 
            }
        } catch (error) {
        }
    };

    const columns = [
        {
          dataField: "_id",
          text: "Project ID",
        },
        {
            dataField: "name",
            text: "Name",
          },
        
        {
          dataField: "liveUsers",
          text: "Live Users",
        },
        {
          dataField: "usersInQueue",
          text: "User IN Queue",
        },
       
      ];

    const options = {
        sizePerPage: 10,
        hideSizePerPage: true,
        showTotal: true,
      };

    return (

        <Container>

<Row>
      

        <Col md={{ span: 2, offset: 8 }}>
          <div style={{ textAlign: "end" }}>
            <Button onClick={fetchLiveData}>Refresh Data</Button>
          </div>
        </Col>
      </Row>
<br/>

      <BootstrapTable
        keyField="_id"
        data={liveData}
        columns={columns}
        pagination={paginationFactory(options)}
      />
        </Container>
    );
};

export default LiveStats;
